body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
}

.em-c-field--date-picker .em-c-field__input {
  background: white !important;
}

div.em-c-alert {
  margin-bottom: 0px;
}

.react-select__control {
  min-height: 44px;
}

.react-select__control--is-focused {
  border: 1px !important;
}

.react-select__menu {
  margin-top: 2px !important;
  border-radius: 0 !important;
}

.react-select__indicator-separator {
  display: none;
}

.react-select__indicator {
  display: none !important;
}

.react-select__multi-value {
  background-color: black !important;
  border-radius: 50px !important;
  color: white;
}

.react-select__multi-value__label {
  color: white !important;
}

.react-select__multi-value__remove:hover {
  border-radius: 50px !important;
  background-color: #0c69b0 !important;
}

.react-select__multi-value__remove:hover > svg {
  color: white !important;
}

.react-select__placeholder {
  color: #0c69b0 !important;
}

.em-c-primary-nav__sublist.em-is-active {
  border: 1px solid black;
  box-shadow: grey 2px 2px 5px;
}

.em-c-solid-card__footer {
  padding: 1.5rem !important;
}
div.react-select__menu {
  z-index: 11;
}

div.em-c-field__body {
  margin-bottom: 0;
}

div.react-select__control {
  min-height: 44px;
}

.em-c-dropdown-check.em-is-active div.em-c-dropdown-check__panel {
  position: relative;
  z-index: 11;
}

@media (min-width: 84em) {
  div.em-l-container {
    max-width: 86em;
  }
}
