.title {
  margin-top: 25px;
  font: normal normal normal 48px/59px EMprintLight;
  color: #111122;
}

.action_bar {
  margin-top: 35px;
  width: 100%;
  padding: 20px;
  background-color: #f2f2f2;
  text-align: right;
}

.upload_area {
  margin-top: 35px;
}

.loader {
  margin: 0 auto;
  margin-top: 15%;
  width: 120px;
}

.loader ~ h1 {
  margin: 30px auto;
}

.pageloading {
  overflow: hidden;
}

.pageloading::before {
  content: '';
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.1;
  z-index: 50;
}

.resultcontainer {
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.checkcontainer {
  width: 170px;
  height: 170px;
  border-radius: 50%;
  margin-right: 30px;
}

.resultok {
  background-color: green;
}

.resultwarn {
  background-color: orange;
  align-self: start;
}

.resulterr {
  background-color: darkred;
}

.check {
  width: 100%;
  height: 100%;
  color: white;
}

.checktitle {
  font-size: 24pt;
  font-family: EMPrint;
}

.checktext {
  font-size: 18pt;
  font-family: EMPrint Light;
}

.checkrow {
  font-weight: bold;
  background-color: lightgray;
  color: black;
  padding: 4px 6px;
  border-bottom: 5px solid red;
}
