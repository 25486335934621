.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 20px 0;
}

.title {
  font-family: EMprintRegular;
  font-size: 28px;
  padding-bottom: 20px;
  border-bottom: 1px solid #707070;
}

.header {
  width: 100%;
  padding: 25px;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
}

.label {
  font-family: EMprintbold;
  margin-bottom: 15px;
  margin-top: 20px;
}

.document {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #707070;
  color: #111122;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.documentNumber {
  font-size: 28px;
}

.vendor {
  font-size: 18px;
}

.info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.details {
  display: flex;
  flex-wrap: wrap;
  width: 50%;
  font-size: 14px;
}

.details div div {
  display: flex;
  align-items: center;
  margin: 3px;
}
.details > div {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 3px;
}

.details > div > svg {
  color: #808080;
}

.actions {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.actions button,
a {
  margin: 4px;
}

.actions button {
  width: 150px;
}

.status {
  padding: 6px;
  color: #fff;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.open {
  background-color: #00b04f;
}

.blocked {
  background-color: #f39b01;
}

.Processed {
  background-color: #000;
}

.Draft {
  background-color: #005eb0;
}

.Cancelled {
  background-color: #b00000;
}

.Paid {
  background-color: #5e00b0;
}

.content {
  padding: 20px;
}

.list {
  margin: 32px 0;
}

.input div div input {
  background-color: white;
}

@media screen and (max-width: 800px) {
  .details {
    width: 100%;
  }

  .actions button {
    width: 100%;
  }

  .actions a {
    width: 100%;
    text-align: center;
  }
}

@media screen and (min-width: 800px) {
  .details > div:last-child {
    width: 100%;
  }
}
